import { default as advertisersY5zffW22E9Meta } from "/opt/buildhome/repo/pages/advertisers.vue?macro=true";
import { default as analyticsFiKBOYLNmQMeta } from "/opt/buildhome/repo/pages/analytics.vue?macro=true";
import { default as authdjl0VO0QO1Meta } from "/opt/buildhome/repo/pages/auth.vue?macro=true";
import { default as history6HGkUfuRcTMeta } from "/opt/buildhome/repo/pages/history.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as page_45for_45gray_45cardinal_45muhaFplivPd2C7Meta } from "/opt/buildhome/repo/pages/page-for-gray-cardinal-muha.vue?macro=true";
import { default as sitesKxt6NdvPCnMeta } from "/opt/buildhome/repo/pages/sites.vue?macro=true";
export default [
  {
    name: "advertisers",
    path: "/advertisers",
    meta: advertisersY5zffW22E9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/advertisers.vue").then(m => m.default || m)
  },
  {
    name: "analytics",
    path: "/analytics",
    meta: analyticsFiKBOYLNmQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/analytics.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/opt/buildhome/repo/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "history",
    path: "/history",
    meta: history6HGkUfuRcTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/history.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "page-for-gray-cardinal-muha",
    path: "/page-for-gray-cardinal-muha",
    meta: page_45for_45gray_45cardinal_45muhaFplivPd2C7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/page-for-gray-cardinal-muha.vue").then(m => m.default || m)
  },
  {
    name: "sites",
    path: "/sites",
    meta: sitesKxt6NdvPCnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sites.vue").then(m => m.default || m)
  }
]